import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive';
import { results } from '../../../contents/mbti-result';
import {
  CopyRight,
  FoodImg2, GrayBoard, GrayBoardTextSub, GrayBoardTitle,
  isMobileProp, LastPageBottom, ResultTextShare, RetryButton, ShareArea,
} from './result';
import arrow from '../../../assets/images/icon/keyboard-arrow-down.png';
import { Share } from '../what-to-eat';
import { Mobile, PC } from '../../../components/layout/mediaQuery';
import { PCWrapper } from '.';
import { MEALION_URL } from '../../../constants/urls';

function Content() {
  const thisUrl = `${MEALION_URL}/game/food-personality`;
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  return (
    <Wrapper>
      <Title m={isMobile}>
        모든 유형 모아보기
      </Title>
      <Flex m={isMobile} style={{ width: isMobile ? '90%' : '35%' }}>
        {
          results.map((result: any) => {
            const [sub, name] = result.sub.split(',');
            const { img } = result;
            const { food } = result;
            return (
              <Board
                m={isMobile}
                key={result.id}
                onClick={() => {
                  navigate(`../${food}`, {
                    state: {
                      retry: true,
                    },
                  });
                }}
              >
                <FoodImage src={`${MEALION_URL}/static/${img}`} m={isMobile} />
                <GrayBoardTextSub m={isMobile} style={{ marginTop: food === 'bibimmyeon' && '-5%' }}>
                  {sub}
                </GrayBoardTextSub>
                <BoardTitle m={isMobile} style={{ margin: isMobile ? 'calc(0.266vw * -2)' : 'calc(0.052vw * -2)' }}>
                  {name}
                </BoardTitle>
              </Board>
            );
          })
      }
        <Text m={isMobile}>
          아직 테스트 전이라면?
        </Text>
        <TestButton retry={false} m={isMobile} onClick={() => navigate('../')}>테스트 하러가기</TestButton>
      </Flex>
      <ResultTextShare m={isMobile}>
        게임 공유하기
      </ResultTextShare>
      <Share thisUrl={thisUrl} isMobile={isMobile} thumbnail={`${MEALION_URL}/static/title_thumbnail-8f85a2c00d572e1227af9d1eb13ca548.png`} game="food-personality" />
      <div style={{
        width: isMobile ? 'calc(0.266vw * 30)' : 'calc(0.052vw * 30)',
        // marginTop: isMobile ? 'calc(0.266vw * 20)' : 'calc(0.052vw * 20) ',
      }}
      >
        <img src={arrow} alt="arrow" width="100%" />
      </div>

      <LastPageBottom />
      <ShareArea m={isMobile}>
        {/* <ResultTextShare m={isMobile}>
            결과 공유하기
          </ResultTextShare>
          <Share thisUrl={thisUrl} isMobile={isMobile} thumbnail={`${MEALION_URL}/static/${foodthumbnail}`} game="food-personality" /> */}
        <CopyRight m={isMobile}>© 2022. mealion Corp. all rights reserved.</CopyRight>
      </ShareArea>
    </Wrapper>
  );
}
function AllResult() {
  return (
    <>
      <Helmet>
        <title>냉장고 게임 모든 유형 모아보기</title>

        <meta property="og:title" content="모든 유형 모아보기" />
        <meta name="description" content="어느 날, 냉장고에서 눈을 떴다." data-react-helmet="true" />
        <meta property="og:description" content="어느 날, 냉장고에서 눈을 떴다." />

        <meta name="twitter:title" content="나와 맞는 음식을 찾아 냉장고에서 탈출하자!" />
        <meta name="twitter:description" content="어느 날, 냉장고에서 눈을 떴다." />

        <meta name="twitter:card" content="summary_large_image" />

        <meta property="og:url" content={`${MEALION_URL}/game/food-personality`} />
        <meta property="og:image" content={`${MEALION_URL}/static/title_thumbnail-8f85a2c00d572e1227af9d1eb13ca548.png`} />
        <meta name="twitter:url" content={`${MEALION_URL}/game/food-personality`} />
        <meta name="twitter:image:src" content={`${MEALION_URL}/static/title_thumbnail-8f85a2c00d572e1227af9d1eb13ca548.png`} />
      </Helmet>
      <PC>
        <PCWrapper>
          <Content />
        </PCWrapper>
      </PC>
      <Mobile>
        <Content />
      </Mobile>
    </>
  );
}
export default AllResult;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
`;

const Title = styled.div<isMobileProp>`
  color: var(--color-black-text);
  font-size: ${(props) => (props.m ? 'calc(0.266vw * 24)' : 'calc(0.052vw * 24)')};
  line-height: ${(props) => (props.m ? 'calc(0.266vw * 24)' : 'calc(0.052vw * 24)')};
  font-weight: 700;
  text-align: center;
  margin-top: ${(props) => (props.m ? 'calc(0.266vw * 60)' : 'calc(0.052vw * 60)')};
`;
const Flex = styled.div<isMobileProp>`
  display: flex;
  flex-wrap: wrap;
  gap: ${(props) => (props.m ? 'calc(0.266vw * 13)' : 'calc(0.052vw * 13)')};
  margin: ${(props) => (props.m ? '0 calc(0.266vw * 20)' : '0 calc(0.052vw * 20)')};
  margin-top: ${(props) => (props.m ? 'calc(0.266vw * 40)' : 'calc(0.052vw * 40)')};
`;
const Board = styled(GrayBoard)`
    margin: 0;
    flex: 1;
    min-width: 45%;
    height: ${(props) => (props.m ? 'calc(0.266vw * 210)' : 'calc(0.052vw * 210)')};
`;

const BoardTitle = styled(GrayBoardTitle)`
  color: var(--color-black-text);
  margin-top: ${(props) => (props.m ? 'calc(0.266vw * -5)' : 'calc(0.052vw * -5)')};
`;

const FoodImage = styled(FoodImg2)`
  margin-top: ${(props) => (props.m ? 'calc(0.266vw * 5)' : 'calc(0.052vw * 5)')};
`;

const Text = styled.div<isMobileProp>`
  color: var(--color-black);
  font-size: ${(props) => (props.m ? 'calc(0.266vw * 14)' : 'calc(0.052vw * 14)')};
  line-height: ${(props) => (props.m ? 'calc(0.266vw * 20)' : 'calc(0.052vw * 20)')};
  letter-spacing: ${(props) => (props.m ? 'calc(0.266vw * -0.33)' : 'calc(0.052vw * -0.33)')};
  font-weight: 500;
  text-align: center;
  width: 100%;
  justify-content: center;
  margin-top: ${(props) => (props.m ? 'calc(0.266vw * 20)' : 'calc(0.052vw * 20)')};
`;
const TestButton = styled(RetryButton)`
  margin: 0;
`;
